import { extendTailwindMerge } from 'tailwind-merge'

type Classes = string | undefined | false | null

export function cx(...classes: Classes[]) {
  return classes.filter(Boolean).join(' ')
}

//this will fix issues where the size of the text is overriding the color
//https://github.com/dcastil/tailwind-merge/blob/v2.2.1/docs/configuration.md
const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        'text-xxs',
        'text-xs',
        'text-sm',
        'text-base',
        'text-lg',
        'text-xl',
        'text-10',
        'text-12',
        'text-13',
        'text-14',
        'text-16',
        'text-20',
        'text-24',
      ],
    },
  },
})

export function tw(...classes: Classes[]) {
  return customTwMerge(classes.filter(Boolean))
}
